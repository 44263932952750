import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layout';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing/landing.component';
import { environment } from '../environments/environment';
import { GuideComponent } from './guide';
import { AuthGuard } from './auth';
import { MetricsComponent } from './metrics/metrics.component';
import { InventoryLandingComponent } from './inventory-management/inventory-landing/inventory-landing.component';
import { InventoryCommonComponent } from './inventory-management/inventory-common/inventory-common.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'guide',
        component: GuideComponent
      },
      {
        path: 'metrics',
        component: MetricsComponent
      },
      {
        path: 'users',
        loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'transactions',
        loadChildren: () => import('./transaction-management/transaction-management.module').then(m => m.TransactionManagementModule)
      },
      {
        path: 'home',
        component: LandingComponent
      },
      {
        path: 'inventory',
        loadChildren: () => import('./inventory-management/inventory-management.module').then(m => m.InventoryManagementModule)
      },
      {
        path: 'price',
        loadChildren: () => import('./pricing-management/pricing-management.module').then(m => m.PricingManagementModule)
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    // {enableTracing: environment.routes, onSameUrlNavigation: 'reload'}
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
