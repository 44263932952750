import { Component, Inject, OnInit } from '@angular/core';
import { DjangoSessionAuthenticationService } from '../../auth';
import { Router } from '@angular/router';
import { AuthorizationData, BackofficeUser } from '../../shared';
import { APP_CONFIG } from '../../configuration';
import { BackofficeConfigurationModel } from '../../configuration';
import { CommonService } from '../../services';
import { SessionStorageService } from '../../services/session-storage.service';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user$: BackofficeUser | boolean;
  selectedSeason: string;
  currentSeason: string;
  headerText: string;


  // tslint:disable-next-line:variable-name
  constructor(@Inject(APP_CONFIG) public app_config: BackofficeConfigurationModel,
              private auth: DjangoSessionAuthenticationService,
              public commonService: CommonService,
              private sessionService: SessionStorageService,
              private localeStorage: LocalStorageService,
              public router: Router) {
  }

  ngOnInit() {
    if (Object.keys(this.commonService.seasons).length === 0) {
      this.commonService.getSeasons().toPromise().then(() => {
        this.setSeason(this.commonService.getSelectedSeason());
      });
    }
    this.commonService.getObservableCurrentSeason().subscribe(
      (current: string) => {
        this.currentSeason = current;
      }
    );
    this.auth.getUserLogged$().subscribe(
      (response) => {
        if (!response) { return; }
        this.user$ = response;
      }
    );
  }

  logout(): void {
    this.auth.logout().subscribe(() => {
      this.localeStorage.clear();
      this.router.navigate(['/login']);
    });
  }

  onChangeSeason(event, value: string): void {
    if (typeof this.user$ === 'boolean') { return; }
    if (!this.router.url.includes('login')){
      this.auth.changeSeason(value).subscribe(data=>{
        this.setSeason(value);
        location.reload();
      })
    }
    // without this, on login we cannot change season
    else{
      this.setSeason(value);
    }

  }

  setSeason(value: string): void {
    this.selectedSeason = value;
    this.sessionService.setItem('season', value);
    this.commonService.setSelectedSeason(value);
    if (this.commonService.seasons[this.selectedSeason])
    {
      if (this.commonService.seasons[this.selectedSeason].openhouse)
        this.headerText = this.app_config.header.text_OH;
      else
        this.headerText = this.app_config.header.text_SAS;
    }


  }

  prettyString(txt: string): string {
    return txt.replace(/-/g, ' ');
  }
}
