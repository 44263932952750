import { Inject, Injectable } from '@angular/core';
import { GeneralHandler } from './general.handler';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { createPopper, Placement } from '@popperjs/core';
import { FlowControlService } from '../services/flow-control.service';

@Injectable()
export class PopoverCreateHandler extends GeneralHandler {

    tooltip: HTMLElement;

    constructor(protected dvmService: DvmService,
                private flowControl: FlowControlService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        super('enter', dvmService);
    }

    protected handle(obj) {
        if (obj.nodes.length && (obj.nodes[0].state === 'available' || obj.nodes[0].state === 'selected')) {
            const node = obj.nodes[0];
            // console.log('Popover Handler:', node);

            // Receive tooltip HTML Element
            this.tooltip = document.querySelector('#tooltip');

            // Generate tooltip content based on node type
            let tooltipContent;
            if (node.type === 'section' && obj.instance.getMapId() === this.dvmConfig.map_id) {
                tooltipContent = this.sectionPopoverContent(node);
            } else if (node.type === 'seat') {
                tooltipContent = this.seatPopoverContent(node);
            }

            // Create content HTML Element, clean previous popover and append
            const tooltipContentHTML = document.createElement('div');
            tooltipContentHTML.innerHTML = tooltipContent.trim();
            const popoverBodyNode = document.querySelector('#tooltip .popover-body');
            while (popoverBodyNode.firstChild) {
                popoverBodyNode.removeChild(popoverBodyNode.firstChild);
            }
            popoverBodyNode.appendChild(tooltipContentHTML);

            // Calculate popover placement
            let placement: Placement;
            if (node.anchor.dom[1] > (document.getElementById('map-interface-card').offsetHeight / 2)) {
                placement = 'top';
            } else {
                placement = 'bottom';
            }

            // Initiate popover
            createPopper(node, this.tooltip, {
                placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: [],
                        }
                    }
                ],
            });

            // Display popover
            this.tooltip.setAttribute('data-show', '');
        }
    }

    public sectionPopoverContent(node) {
        const areaObject = this.flowControl.availabilityBlockmap[node.id];
        const areaName = node.id.split('_')[1];
        const tooltipContent = `
                            <ul class='list-unstyled ticket-selected'>
                                <li style='text-align: center;'>
                                    <span class='ticket-lab'>Section</span>
                                    <span class='ticket-val'>` + areaName + `</span>
                                </li>
                            </ul>`;

        return tooltipContent;

    }

    public seatPopoverContent(node) {
        let seatObject;
        if (this.flowControl.seatAll) {
          seatObject = this.flowControl.seatAll;
        } else {
          seatObject = this.flowControl.availabilitySeatmap[node.id];
        }
        // Seatmap
        const seating = {
            id: seatObject.id,
            section: seatObject.id.split('-')[0].split('_')[1],
            row: seatObject.id.split('-')[1],
            seat: seatObject.id.split('-')[2],
            get price_type() {
                if (seatObject.price_type === null) {
                    return undefined;
                } else {
                    return seatObject.price_type;
                }
            }

        };
        let content =
            '<ul style=\'width: 280px\' class=\'flex ticket-selected-seat\'>' +
            '<li style=\'display:block;\'>' +
            '<span class=\'ticket-lab\'>Section</span> ' +
            '<span class=\'ticket-val\'>' + seating.section + '</span>' +
            '</li>' +
            '<li>' +
            '<span class=\'ticket-lab\'>Row</span> ' +
            '<span class=\'ticket-val\'>' + seating.row + '</span>' +
            '</li>' +
            '<li>' +
            '<span class=\'ticket-lab\'>Seat</span> ' +
            '<span class=\'ticket-val\'>' + seating.seat + '</span>' +
            '</li>' +
            '</ul>' +
            '<ul class=\'list-unstyled ticket-selected\'>' +
            '<li style=\'display:block;\'>' +
            '<span class=\'ticket-lab\'>Price Type:</span> ' +
            '<span class=\'ticket-val\'>' + seating.price_type.name + '</span>' +
            '</li>';
        if (Array.isArray(seating.price_type.prices)) {
            for (const price of seating.price_type.prices) {
                content +=
                    '<li style=\'display:block;\'>' +
                    '<span class=\'ticket-lab\'>Price:</span> ' +
                    '<span class=\'ticket-val\'>$' + price.price + '</span>' +
                    '</li>' +
                    '</ul>';
            }
        }
        return content;
    }

}
