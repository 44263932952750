import { Injectable } from '@angular/core';
import { MetricModel } from './metric.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoggedMetricModel } from './models/logged-metric.model';
import { InventoryAvailableModel } from './models/inventory-available.model';
import { map } from 'rxjs/operators';
import { MetricService } from './metric.service';
import { ActiveUserModel, LoggedInMetricsModel, PriceTypeInventoryAvailableModel } from './models';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {


  constructor(private http: HttpClient, private metricsService: MetricService) {
  }

  getUsers(): Observable<MetricModel> {
    return this.http.get<MetricModel>(`/metrics`);
  }

  // public parseData(dataObj: MetricModel): object {
  //   const data = {};
  //   for (const [key, value] of Object.entries(dataObj)) {
  //     data[key] = [];
  //     for (const [childKey, childValue] of Object.entries(value)) {
  //       data[key].push({name: this.toTitleCase(childKey), value: childValue});
  //       }
  //     }
  //   return data;
  // }

  // public parseLoggedInfo(dataObj: LoggedMetricModel): object {
  //   const loggedInfo = dataObj;
  //   const data = {};
  //   for (const obj of Object.keys(loggedInfo)) {
  //     data[obj] = {name: obj, value: dataObj[obj]};
  //   }
  //   return data;
  // }

  public parseData(dataObj): any {
    const data = {};
    const results = [];
    for (const obj of Object.keys(dataObj)) {
        results.push({name: this.toTitleCase(obj), value: dataObj[obj]});
    }
    return results;
  }

  public parseAddonsPriceType(dataObj): any {
    const data = {};
    const results = [];
    for (const obj of dataObj) {
        results.push({name: this.toTitleCase(obj.name), value: obj.balance});
    }
    return results;
  }


  public parseReleasePriceType(dataObj): any {
    const data = {};
    const results = [];
    for (const obj of dataObj) {
        results.push({name: this.toTitleCase(obj.price_type_name), value: obj.quantity});
    }
    return results;
  }

  public parseDataPriceType(inventory: Array<PriceTypeInventoryAvailableModel>): any {
    const response = {};
    const results = [];
    // Custom STAPLES only support one plan
    const pricetypeplan = this.searchPlan('Full', inventory);
    response['plan'] = pricetypeplan.name;
    const list = pricetypeplan.price_type_availability;
    for (const obj of list) {
      if (obj.name !== 'Lexus Club' && obj.name !== 'Parking Lot C' && obj.name !== 'Parking Lot W' && obj.name !== 'Lexus Club C') {
        results.push({name: this.toTitleCase(obj.name), value: obj.seats});
      }
    }
    return results;
  }

  private toTitleCase(str: string): string {
    const word = str.toLowerCase().split('_');
    for (let i = 0; i < word.length; i++) {
      word[i] = word[i][0].toUpperCase() + word[i].slice(1);
    }
    return word.join(' ');
  }

  public getUserConversionData() {
    return this.metricsService.getConversion();
  }

  public getActiverUserSite(): Observable<ActiveUserModel> {
    return this.metricsService.getActiveUsers();
  }

  public parseLoggedInData(dataObj: LoggedInMetricsModel): any {
    const loggedInPercentage = parseFloat((dataObj.logged / dataObj.total_users * 100).toFixed(2));
    const notLoggedInPercentage = 100 - loggedInPercentage;
    return [{name: 'Logged In', value: loggedInPercentage}, {name: 'Not Logged In', value: notLoggedInPercentage}];
  }
  // public parseAddonsPriceType(dataObj): any {
  //   return this._miau(dataObj, "name", "balance");
  // }
  // ​
  // public parseReleasePriceType(dataObj): any {
  //   return this._miau(dataObj, "price_type_name", "quantity");
  // }
  // ​
  // private _miau(dataObj, name_prop: string, value_prop: string) {
  //   const data = {};
  //   const results = [];
  //   for (const obj of dataObj) {
  //     results.push({name: this.toTitleCase(obj[name_prop]), value: obj[value_prop]});
  //   }
  //   return results;
  // }

  searchPlan(planTarget: string, list: Array<PriceTypeInventoryAvailableModel>): PriceTypeInventoryAvailableModel {
    return list.filter((item) => item.tier === 1)[0];
  }
}
