import {Component, OnInit} from '@angular/core';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ChartsService, LoggedMetricModel, MetricModel} from 'src/app/charts';
import {MetricService} from 'src/app/charts/metric.service';
import {InventoryAvailableModel} from 'src/app/charts/models/inventory-available.model';
import {UserLoadBarModel} from '../../charts/models';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  userConversion;
  userConversionData;
  userBar: UserLoadBarModel;
  pricetypeInventory: boolean;
  planInventoryAvailableData;
  onlineUsers: number;
  tableUsers;
  deviceCategory;
  deviceCategoryData;

  dynamic = 70;
  intervalId;

  intervalList: Array<any>;

  horizontalChartBaseConfig;
  verticalBarChartBaseConfig;
  pieChartBaseConfig;

  userLoggedInData;
  userLoggedInConfig;

  constructor(private chartService: ChartsService,
              private metricService: MetricService) {
    this.intervalList = [];
    this.userBar = {} as UserLoadBarModel;
    this.onlineUsers = 0;
  }

  ngOnInit() {
    this.setUserMetrics();
    this.setInventoryAvailableMetric();
    this.intervalList[0] = setInterval(() => {
      this.setUserMetrics();
    }, 60000);
    // 60000 = 1 minutes
    // 1800000 = 30 minutes
    this.intervalList[1] = setInterval(() => {
      this.setInventoryAvailableMetric();
    }, 300000);
    this.setUsersLoggedInMetrics();
    // 300000 = 5 minutes
  }

  // Load user metrics and set values
  private setUserMetrics(): void {
    this.chartService.getUserConversionData().subscribe(
      loggedMetric => {
        this.setUserBar(loggedMetric);
        this.setUserStatistics(this.chartService.parseData(loggedMetric));
      }
    );
    this.chartService.getActiverUserSite().subscribe(
      activeUsers => {
        this.tableUsers = activeUsers;
        this.onlineUsers = Object.keys(activeUsers).length;
      }
    );
  }

  private setInventoryAvailableMetric(): void {
    this.metricService.getPriceTypeAvailability().subscribe(
      pricetypeInventory => {
        this.planInventoryAvailableData = this.chartService.parseDataPriceType(pricetypeInventory);
        console.log(this.planInventoryAvailableData);
        this.pricetypeInventory = true;
        // this.planInventoryAvailableData = this.chartService.parseDataPriceType(pricetypeInventory);
        this.verticalBarChartBaseConfig = {
          view: [300, 0],
          results: this.planInventoryAvailableData,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#002766', '#002766', '#003180', '#003b99', '#0044b3', '#004ecc', '#0058e6', '#0062ff', '#1a71ff',
              '#3381ff', '#4d91ff', '#66a1ff', '#80b0ff', '#99c0ff', '#b3d0ff', '#cce0ff', '#e6efff']
          },
          animations: true,
          labels: false,
          gradient: false,
          legend: false,
          legendTitle: 'defautLegend',
          xAxisLabel: 'defaultX',
          yAxisLabel: 'defaultY',
          roundEdges: true
        };
      }
    );
  }

  private setUserStatistics(metrics): void {
    this.userConversionData = true;
    this.horizontalChartBaseConfig = {
      view: [0, 300],
      results: metrics,
      legend: false,
      legendTitle: 'default',
      xAxisLabel: 'Total Users',
      yAxisLabel: '',
      scheme: {
        // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
        domain: ['#002766', '#003180', '#003b99', '#0044b3', '#004ecc', '#0058e6', '#0062ff', '#1a71ff',
          '#3381ff', '#4d91ff', '#66a1ff']
      }
    };
  }

  private setUserBar(metrics: LoggedMetricModel): void {
    this.userBar.relocated_users = metrics.with_transaction_complete;
    this.userBar.total_users = metrics.total_users;
    this.userBar.porcentaje = metrics.with_transaction_complete /  metrics.total_users * 100;
  }

  // startBar(): void {
  //   this.dynamic = 0;
  //   if (this.intervalId) {
  //     clearTimeout(this.intervalId);
  //   }
  //   const test = () => {
  //     if (this.dynamic < 100) {
  //       return setTimeout(() => {
  //         this.dynamic++;
  //         this.intervalId = test();
  //       }, 1000);
  //     }
  //   };
  //   this.intervalId = test();
  // }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  private setUsersLoggedInMetrics(): void {
    this.metricService.getUsersLoggedIn().subscribe(
      userLoggedInData => {
        this.userLoggedInData = userLoggedInData;
        this.userLoggedInConfig = {
          view: [],
          results: this.chartService.parseLoggedInData(this.userLoggedInData),
          animations: true,
          labels: true,
          legend: false,
          legendPosition: 'below',
          legendTitle: 'Logged in vs not logged in',
          doughnut: false,
          gradient: false,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#129DFF', '#003180', '#003b99', '#0044b3', '#004ecc', '#0058e6', '#0062ff', '#1a71ff',
              '#3381ff', '#4d91ff', '#66a1ff']
          }
        };
      }
    );
  }

  // TODO Check perfomance
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.intervalList.forEach(interval => {
      clearInterval(interval);
    });
  }

}
