import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { InventoryListModel } from "../shared/models/inventory-list.model";
import { InventoryService } from "../services";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: 'app-inventory-change-status-modal',
  templateUrl: './inventory-change-status-modal.component.html',
  styleUrls: ['./inventory-change-status-modal.component.scss']
})
export class InventoryChangeStatusModalComponent implements OnInit {
  // mini preparation for multi change status
  @Input() seats: InventoryListModel[];
  seatStatusChange: 'available' | 'internal_hold' | 'available_internal';
  constructor(public bsModalRef: BsModalRef, private inventoryService: InventoryService,
              private notificationService: NotificationService,) { }

  ngOnInit() {
  }

  close() {
    this.bsModalRef.hide();
  }

  onSaveStatusChange(): void {
    // mini preparation for multi change status
    let seatList = [];
    for (const inventoryInfo of this.seats) {
      seatList.push(inventoryInfo.id);
    }
    this.inventoryService.changeSeatStatus(seatList, this.seats[0].plan_id, this.seatStatusChange).subscribe(
      data => {
        this.notificationService.showSuccess('Seat status saved');
        for (const inventoryInfo of this.seats) {
          inventoryInfo.status = this.seatStatusChange;
        }
        this.close();
      }
    );
  }
}
