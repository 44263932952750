import { Component, Inject, OnInit } from '@angular/core';
import { SectionDataModel } from '../shared/models/section-data.model';
import { SeatDataModel } from '../shared/models/seat-data.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { PriceTypeModel } from 'src/app/shared';
import { FlowControlService } from '../services/flow-control.service';
import { PricingService } from '../services';
import { NotificationService } from 'src/app/services/notification.service';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';

@Component({
  selector: 'app-side-interface',
  templateUrl: './side-interface.component.html',
  styleUrls: ['./side-interface.component.scss']
})
export class SideInterfaceComponent implements OnInit {

  priceTypeSelected: number;
  sectionData: SectionDataModel;
  priceTypeList: Array<PriceTypeModel>;
  isAllSeatsSelected = false;
  seatData: SeatDataModel;
  seatSelectedLenght: number;
  bsModalRef: BsModalRef;

  constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
              private dvmService: DvmService,
              public flowService: FlowControlService,
              private pricingService: PricingService,
              private modalService: BsModalService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.flowService.getSectionData().subscribe(
      data => {
        this.sectionData = data;
      }
    );
    this.flowService.getSeatData().subscribe(
      data => {
        this.seatData = data;
        if (this.seatData) {
          this.priceTypeSelected = this.seatData.price_type.id;
        } else {
          this.priceTypeSelected = 0;
        }
      }
    );
    this.pricingService.getSeatSelectedLength().subscribe(
      length => {
        this.seatSelectedLenght = length;
      }
    );
    this.pricingService.getPriceTypes().subscribe(
      priceList => {
        this.priceTypeList = priceList;
      }
    );

  }

  onChangeCheckboxActive(event): void {
    this.isAllSeatsSelected = event;
    if (this.isAllSeatsSelected) {
      this.pricingService.seatsSelected = this.dvmService.viewer.getNodesByType('seat');
      this.dvmService.viewer.select(this.pricingService.seatsSelected);
    } else {
      this.dvmService.viewer.unselectAll();
      this.pricingService.seatsSelected = [];
    }
    if (!this.flowService.seatAll) {
      this.pricingService.setSeatSelectedLength(this.dvmService.viewer.getNodesByState('seat', 'selected').length);
    }
  }

  get getSeatSelected(): number {
    return this.seatSelectedLenght;
  }

  onSave(): void {
    // this.flowService.renewHash();
    let toRequest: Array<string> = [];
    if (this.flowService.seatAll) {
      toRequest.push(this.flowService.seatAll.id);
    } else {
      toRequest = Object.keys(this.flowService.seatHash);
    }

    this.pricingService.setSeatPriceType(this.priceTypeSelected, toRequest).subscribe(
      data => {
        this.pricingService.getSectionSeatPriceType(this.dvmService.viewer.getMapId()).subscribe(
          seatPriceType => {
            this.notificationService.showSuccess('Price type saved successfully');
            this.flowService.availabilitySeatmap = this.pricingService.getSeatmapAvailability(seatPriceType.seats);
          }
        );
      },
      error => {
        this.notificationService.showError('Could not save price type');
      }
    );
  }

  getseatSection(seatID: string): string {
    return seatID.split('-')[0].split('_')[1];
  }
  getseatRow(seatID: string): string {
    return seatID.split('-')[1];
  }
  getseatSeat(seatID: string): string {
    return seatID.split('-')[2];
  }

  newPriceType(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: false,
      ignoreBackdropClick: true,
      initialState: {
        priceTypeprice: undefined
      }
    };
    // @ts-ignore
    this.bsModalRef = this.modalService.show(PriceTypeModalComponent, modalConfig);
    const subscription = this.modalService.onHide.subscribe((reason: string) => {
      subscription.unsubscribe();
      this.priceTypeList.push(this.bsModalRef.content.responsePriceType);
    });
  }

  onClearSelection(): void {
    console.log(this.flowService.seatHash);
    console.log(Object.keys(this.flowService.seatHash).length);
    if (this.seatSelectedLenght !== 0) {
      this.dvmService.viewer.unselectAll();
      this.flowService.seatHash = {};
      this.pricingService.setSeatSelectedLength(Object.keys(this.flowService.seatHash).length);
      this.flowService.setSeatData(null);
      this.isAllSeatsSelected = false;
    }
  }

  goBack() {
    const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
    loadOptions.venue_id = this.dvmService.viewer.getVenueId();
    this.dvmService.viewer.unselectAll();
    this.isAllSeatsSelected = false;
    this.flowService.flowStep = 'blockmap';
    this.flowService.setSeatData(null);
    this.flowService.seatHash = {};
    this.pricingService.setSeatSelectedLength(Object.keys(this.flowService.seatHash).length);
    this.dvmService.loadMap(loadOptions);
  }

  isTBorSuite(): SeatDataModel {
    return this.flowService.seatAll;
  }
}
