import { Injectable } from '@angular/core';
import { HorizontalBarModel } from './horizontal-bar-chart/horizontal-bar.model';
import { PieChartModel } from './pie-chart/pie-chart.model';
import { VerticalBarChartModel } from './vertical-bar-chart/vertical-bar-chart.model';
import { AdvancedPieModel } from './advanced-pie-chart/advanced-pie.model';

@Injectable({
  providedIn: 'root'
})
export class ChartConfigService {


  constructor() { }

  public setHorizontalBarChartConfig(newConfig: object): HorizontalBarModel {
    const config = this.getHorizontalBarChartDefaultConfig();
    return this.overrideObjects(config, newConfig);
  }

  private getHorizontalBarChartDefaultConfig(): HorizontalBarModel {
    const chartBaseConfig: HorizontalBarModel = {
      view: [940, 300],
      results: [],
      scheme: {
        domain: ['#2E5266', '#6E8898', '#9FB1BC', '#D3D0CB', '#E2C044']
      },
      animations: true,
      legend: true,
      legendTitle: 'default',
      legendPosition: 'right',
      xAxis: true,
      yAxis: true,
      showGridLines: true,
      roundDomains: false,
      showXAxisLabel: true,
      showYAxisLabel: true,
      xAxisLabel: 'defaultX',
      yAxisLabel: 'defaultY',
      trimXAxisticks: true,
      trimYAxisticks: true,
      rotateXAxisTicks: true,
      maxXAxisTickLenght: 16,
      maxYAxisTickLenght: 16,
      showDataLabel: false,
      noBarWhenZero: true,
      gradient: false,
      barPadding: 8,
      tooltipDisabled: false
    };

    return chartBaseConfig;
  }

  public setPieChartConfig(newConfig: object): PieChartModel {
    const config = this.getPieChartDefaultConfig();
    return this.overrideObjects(config, newConfig);
  }

  private getPieChartDefaultConfig(): PieChartModel {
    const chartBaseConfig: PieChartModel = {
      view: [500, 500],
      results: [],
      scheme: {
        domain: ['#2E5266', '#6E8898', '#9FB1BC', '#D3D0CB', '#E2C044']
      },
      animations: true,
      labels: false,
      legend: false,
      legendTitle: 'defautLegend',
      doughnut: false,
      gradient: false,
      explodeSlices: false,
      tooltipDisabled: false,

    };
    return chartBaseConfig;
  }

  public setVerticalBarChartConfig(newConfig: object): VerticalBarChartModel {
    const config = this.getVerticalBarChartDefaultConfig();
    return this.overrideObjects(config, newConfig);
  }

  private getVerticalBarChartDefaultConfig(): VerticalBarChartModel {
    const chartBaseConfig: VerticalBarChartModel = {
      view: [300, 940],
      results: [],
      scheme: {
        domain: ['#2E5266', '#6E8898', '#9FB1BC', '#D3D0CB', '#E2C044']
      },
      animations: true,
      labels: true,
      gradient: false,
      legend: true,
      legendTitle: 'defautLegend',
      legendPosition: 'right',
      tooltipDisabled: false,
      xAxis: true,
      yAxis: true,
      xAxisLabel: 'defaultX',
      yAxisLabel: 'defaultY',
      showGridLines: true,
      showDataLabel: true,
      noBarWhenZero: true,
      roundEdges: true
    };

    return chartBaseConfig;
  }
  public setAdvancedPieChartConfig(newConfig: any): AdvancedPieModel {
    const config = this.getAdvancedPieChartConfig();
    return this.overrideObjects(config, newConfig);
  }

  private getAdvancedPieChartConfig(): AdvancedPieModel {
    const chartBaseConfig: PieChartModel = {
      view: [500, 500],
      results: [],
      scheme: {
        domain: ['#2E5266', '#6E8898', '#9FB1BC', '#D3D0CB', '#E2C044']
      },
      animations: true,
      labels: false,
      legend: false,
      legendTitle: 'defautLegend',
      doughnut: false,
      gradient: false,
      explodeSlices: false,
      tooltipDisabled: false,

    };
    return chartBaseConfig;
  }

  private overrideObjects(config: object, newConfig: object): any {
    for (const key of Object.keys(newConfig)) {
      config[key] = newConfig[key];
    }
    return config;

  }

}
