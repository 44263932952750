import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InventoryManagementRoutingModule } from './inventory-management-routing.module';
import { InventoryLandingComponent } from './inventory-landing/inventory-landing.component';
import { SideInterfaceComponent } from './side-interface/side-interface.component';
import { PaginationModule, TabsModule } from 'ngx-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { AssignCustomerModalComponent } from './assign-customer-modal/assign-customer-modal.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';
import { InventoryCommonComponent } from './inventory-common/inventory-common.component';
import { PlanSeatCommentModalComponent } from './plan-seat-comment-modal/plan-seat-comment-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InventoryChangeStatusModalComponent } from './inventory-change-status-modal/inventory-change-status-modal.component';


@NgModule({
  declarations: [
    InventoryLandingComponent,
    SideInterfaceComponent,
    AssignCustomerModalComponent,
    InventoryListComponent,
    InventoryCommonComponent,
    PlanSeatCommentModalComponent,
    InventoryChangeStatusModalComponent
  ],
  imports: [
    CommonModule,
    InventoryManagementRoutingModule,
    TabsModule,
    FormsModule,
    SharedModule,
    PaginationModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  entryComponents: [
    AssignCustomerModalComponent,
    PlanSeatCommentModalComponent,
    InventoryChangeStatusModalComponent
  ]
})
export class InventoryManagementModule { }
