import { Component, OnInit } from '@angular/core';
import { PaginatedResponseModel } from '../../shared/models';
import { SalesRepModel, SalesRepFilterModel } from '../shared/models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UserService } from '../services';
import { NgForm } from '@angular/forms';
import { CommonService } from '../../services';
import { SalesRepService } from '../services/sales-rep.service';
import { SalesRepModalDetailsComponent } from '../sale-rep-modal-details';
// import { SalesRepModalDetailsComponent } from '../sale-rep-modal-details';

@Component({
  selector: 'app-sales-rep-list',
  templateUrl: './sales-rep-list.component.html'
})
export class SalesRepListComponent implements OnInit {

  repList: PaginatedResponseModel<SalesRepModel>;
  filter: SalesRepFilterModel = {} as SalesRepFilterModel;
  bsModalRef: BsModalRef;
  // Pagination variables
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;

  constructor(private salesRepService: SalesRepService, private modalService: BsModalService, private commonService: CommonService) {
    this.currentPage = 1;
    this.itemsPerPage = 10; // Same number in API Settings.
    this.repList = {} as PaginatedResponseModel<SalesRepModel>;
    this.filter.is_active = '';
    this.filter.type = '';
  }

  ngOnInit() {
    this.init();
  }

  init(): void {
    this.salesRepService.getPaginatedSalesRep(this.currentPage).subscribe(
      repList => {
        repList.results = this.findAndDestroySuperAdmin(repList.results);
        this.repList = repList;
        this.totalItems = repList.count;
      }
    );
  }

  onSubmitFilter(): void {
    this.currentPage = 1;
    this.applyFilter();
  }

  applyFilter(): void {
    this.salesRepService.getFilteredSalesRep(this.currentPage, this.filter).subscribe(
      data => {
        data.results = this.findAndDestroySuperAdmin(data.results);
        this.repList = data;
        this.totalItems = this.repList.count;
      });
  }

  restartForm(form: NgForm): void {
    this.currentPage = 1;
    this.filter = {
      first_name: '',
      last_name: '',
      is_active: '',
      email: '',
      username: '',
      type: '',
    };
    form.form.markAsUntouched();
    form.form.markAsPristine();
    this.init();
  }

  onOpenModal(target: SalesRepModel = null): void {

    const initialState = {
      user: target
    };

    this.bsModalRef = this.modalService.show(SalesRepModalDetailsComponent,
      {class: 'modal-dialog-centered', ignoreBackdropClick: true, initialState});

    const subscription = this.modalService.onHide.subscribe((reason: string) => {
      subscription.unsubscribe();
      if (this.bsModalRef.content.action === 'update') {
        this.currentPage = 1;
        this.init();
      }

    });
  }

  pageChanged(event): void {
    this.currentPage = event.page;

    if (this.commonService.filterisEmpty(this.filter)) {
      this.init();
    } else {
      this.applyFilter();
    }
  }

  removeUnderLine(text: string): string {
    return text.replace('_', ' ');
  }

  translateUserType(text: string): string {
    switch (text) {
      case 'sales_person':
        return 'Rep';
      case 'admin':
        return 'Admin';
      case 'management':
        return 'Manager';
      case 'sales_person_PR':
        return 'Rep PR';
    }
  }

  // Remove any superadmin user
  private findAndDestroySuperAdmin(list: Array<SalesRepModel>): Array<SalesRepModel> {
    const index = list.findIndex((rep) =>  rep.username.includes('superadmin') || rep.is_superuser === true);
    if ( index >= 0 ) {
      list.splice(index, 1);
    }
    return list;
  }
}
