import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { SessionStorageService } from "../../services/session-storage.service";

@Injectable()
export class AddSeasonUrlInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionStorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const season = this.sessionService.getItem('season');
    let finalReq = req;
    if (season) {
      finalReq = req.urlWithParams.includes('?') ? req.clone({ url: `${req.urlWithParams}&season=${season}` })
        : req.clone({ url: `${req.urlWithParams}?season=${season}` });
    }

    return next.handle(finalReq);
  }
}
