import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InventoryLandingComponent } from './inventory-landing/inventory-landing.component';
import { AuthGuard } from '../auth';
import { InventoryCommonComponent } from './inventory-common/inventory-common.component';
import { UserListComponent } from '../user-management/user-list/user-list.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';
import { InventoryResolveService } from './services';


const routes: Routes = [
  {
    path: '',
    component: InventoryCommonComponent,
    children: [
      {
        path: 'map',
        component: InventoryLandingComponent,
        resolve: { plansInfo: InventoryResolveService }
      },
      {
        path: 'list',
        component: InventoryListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryManagementRoutingModule { }
