import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AddApiUrlInterceptor, WithCredentialsInterceptor, HttpXsrfInterceptorMod, MessagingService, ModalComponent,
  AuthInterceptor } from './shared';
import { HeaderComponent } from './layout/header/header.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { LandingModule } from './landing/landing.module';
import { DjangoSessionAuthenticationService } from './auth';
import { UserManagementModule } from './user-management/user-management.module';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule, PaginationModule, TabsModule } from 'ngx-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TransactionManagementModule } from './transaction-management/transaction-management.module';
import { PricingManagementModule } from './pricing-management/pricing-management.module';
import { InventoryManagementModule } from './inventory-management/inventory-management.module';
import { GuideComponent } from './guide';
import { APP_CONFIG, ApplicationConfiguration } from './configuration';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MetricsComponent } from './metrics/metrics.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsManagementModule } from './charts/charts-management.module';
import { DVM_CONFIG, DVMConfig } from './configuration/dvm.configuration';
import { DvmService } from './shared/services/dvm.service';
import { Router } from '@angular/router';
import { AuthModalService } from './shared/auth-modal/auth-modal.service';
import { AddSeasonUrlInterceptor } from './shared/interceptors/add-season-url.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HeaderComponent,
    SideNavComponent,
    LoginComponent,
    GuideComponent,
    MetricsComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    LandingModule,
    UserManagementModule,
    TransactionManagementModule,
    PricingManagementModule,
    InventoryManagementModule,
    ToastrModule.forRoot(),
    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ChartsManagementModule,
    NgxChartsModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AddApiUrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptorMod, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AddSeasonUrlInterceptor, multi: true},
    DjangoSessionAuthenticationService,
    MessagingService,
    {provide: APP_CONFIG, useValue: ApplicationConfiguration},
    {provide: DVM_CONFIG, useValue: DVMConfig},
    DvmService,
    AuthModalService,
    {provide: LOCALE_ID, useValue: 'en-US'}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent]
})
export class AppModule { }
