import { Component, OnInit } from '@angular/core';
import { InventoryListModel } from '../shared/models/inventory-list.model';
import { BsModalRef } from 'ngx-bootstrap';
import { InventoryService } from '../services';
import { NotificationService } from '../../services/notification.service';
import { BackofficeUser } from '../../shared';
import { UserService } from '../../user-management/services';
import { PlanSeatCommentModel } from '../../pricing-management/shared';
import { SideInterfaceComponent } from '../side-interface/side-interface.component';

@Component({
  selector: 'app-plan-seat-comment-modal',
  templateUrl: './plan-seat-comment-modal.component.html',
  styleUrls: ['./plan-seat-comment-modal.component.scss']
})
export class PlanSeatCommentModalComponent implements OnInit {

  actuator: BackofficeUser;
  plan: string;
  seat: InventoryListModel;
  comment: PlanSeatCommentModel;
  closeReason: 'cancel' | 'add' | 'edit' | 'delete';
  action: 'create' | 'edit';

  constructor(public bsModalRef: BsModalRef, private userService: UserService,
              private inventoryService: InventoryService,
              private toast: NotificationService) { }

  ngOnInit() {
  }

  saveComment(htmlTextAreaRef: HTMLTextAreaElement): void {
    const comment: PlanSeatCommentModel = {
      seat: this.seat.id,
      plan: parseInt(this.plan, 10),
      comment: htmlTextAreaRef.value,
    };
    if (this.seat.comments[0] && !SideInterfaceComponent.isEmpty(htmlTextAreaRef.value)) { // Comment existe, actualizar
      comment.id = this.seat.comments[0].id;
      this.editComment(comment);
    } else if (this.seat.comments.length === 0) { //  No hay comentarios, creamos.
      console.log(comment);
      this.addComment(comment);
    } else if (this.seat.comments[0].id && SideInterfaceComponent.isEmpty(htmlTextAreaRef.value)) { // si esta vacio, lo borramos.
      comment.id = this.seat.comments[0].id;
      this.removeComment(comment.id);
    }
  }

  addComment(comment: PlanSeatCommentModel): void {
    comment.created_by =  this.actuator.id;
    comment.updated_by = this.actuator.id;
    this.inventoryService.createComment(comment).subscribe((respone) => {
      console.log('CREATED!');
      this.toast.showSuccess('Comment has been saved correctly');
      this.comment = respone;
      this.onClose('add');
    });
  }

  removeComment(commentId: number): void {
    this.inventoryService.deleteComment(commentId).subscribe(() => {
      console.log('DELETED!');
      this.toast.showSuccess('Comment has been deleted correctly');
      this.onClose('delete');
    });
  }

  editComment(comment: PlanSeatCommentModel): void {
    comment.updated_by = this.actuator.id;
    this.inventoryService.updateComment(comment).subscribe((response) => {
      console.log('UPDATED!');
      this.toast.showSuccess('Comment has been updated correctly');
      this.comment = response;
      this.onClose('edit');
    });
  }

  onClose(reason: 'cancel' | 'add' | 'edit' | 'delete'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }
}
