import { Component, OnInit } from '@angular/core';
import { MetricService } from '../charts/metric.service';
import { ChartsService } from '../charts';
import { InventoryService } from '../inventory-management/services';
import { PlanModel } from '../shared';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit {

  premiumPlan: PlanModel;

  addonsPerPriceType;
  addonsPerPriceTypeData;

  releasesPerPriceType;
  releasesPerPriceTypeData;

  keepsPerPriceType;
  keepsPerPriceTypeData;

  // deviceCategory; // analytics comment
  deviceCategoryData;

  addonsBalance;
  addonsBalanceData;

  verticalBarChartBaseConfig;
  // pieChartBaseConfig; // analytics comment
  advancedPieChartBaseConfig;

  totalRevenueBySaleData;
  totalRevenueBySaleConfig;

  constructor(private metricService: MetricService,
              private inventoryService: InventoryService,
              private chartService: ChartsService) { }

  ngOnInit() {
    this.inventoryService.getPlans().subscribe((planList) => {
      this.premiumPlan = planList.filter((plan) => plan.tier === 1 )[0];
      this.setAddonsBalance();
      // this.setDeviceCategoryMetric(); // analytics comment
      this.setAddonsPerPriceType();
      this.setReleasesPerPriceType();
      this.setKeepPerPriceType();
      this.setTotalRevenueBySale();
    });
  }

  // Loads metric addons balance and set values
  private setAddonsBalance(): void {
    this.metricService.getTotalBalance().subscribe(
      response => {
        this.addonsBalance = response;
        this.addonsBalanceData = this.chartService.parseAddonsPriceType(this.addonsBalance);
        this.advancedPieChartBaseConfig = {
          view: [],
          results: this.addonsBalanceData,
          animations: true,
          labels: true,
          legend: true,
          legendPosition: 'below',
          legendTitle: 'Balance',
          doughnut: false,
          gradient: false,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#002766', '#003180', '#003b99', '#0044b3', '#004ecc', '#0058e6', '#0062ff', '#1a71ff',
              '#3381ff', '#4d91ff', '#66a1ff']
          }
        };
      }
    );
  }
  // // Loads metric devices and set values
  // private setDeviceCategoryMetric(): void {
  //   this.metricService.getDeviceCategory().subscribe(
  //     deviceCategory => {
  //       this.deviceCategory = deviceCategory;
  //       this.deviceCategoryData = this.chartService.parseData(this.deviceCategory);
  //       this.pieChartBaseConfig = {
  //         view: [],
  //         results: this.deviceCategoryData,
  //         animations: true,
  //         labels: true,
  //         legend: false,
  //         legendPosition: 'below',
  //         legendTitle: 'Platform used',
  //         doughnut: false,
  //         gradient: false,
  //         scheme: {
  //           // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
  //           domain: ['#002766', '#003180', '#003b99', '#0044b3', '#004ecc', '#0058e6', '#0062ff', '#1a71ff',
  //             '#3381ff', '#4d91ff', '#66a1ff']
  //         }
  //       };
  //     }
  //   );
  // } // analytics comment

  // Loads metric addons per price type and set values
  private setAddonsPerPriceType(): void {
    this.metricService.getAddonsPerPriceType(this.premiumPlan.id).subscribe(
      response => {
        this.addonsPerPriceType = response;
        this.addonsPerPriceTypeData = this.chartService.parseReleasePriceType(this.addonsPerPriceType);
        this.verticalBarChartBaseConfig = {
          view: [300, 0],
          results: this.addonsPerPriceTypeData,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#002766', '#003180', '#003b99', '#0044b3', '#004ecc', '#0058e6', '#0062ff', '#1a71ff',
              '#3381ff', '#4d91ff', '#66a1ff']
          },
          animations: true,
          labels: false,
          gradient: false,
          legend: false,
          legendTitle: 'defautLegend',
          xAxisLabel: 'defaultX',
          yAxisLabel: 'defaultY',
          roundEdges: true
        };
      }
    );
  }

  // loads metric releases per price type and set values
  private setReleasesPerPriceType(): void {
    this.metricService.getReleasesPerPriceType(this.premiumPlan.id).subscribe(
      response => {
        this.releasesPerPriceType = response;
        this.releasesPerPriceTypeData = this.chartService.parseReleasePriceType(this.releasesPerPriceType);
        this.verticalBarChartBaseConfig = {
          view: [300, 0],
          results: this.releasesPerPriceTypeData,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#002766', '#003180', '#003b99', '#0044b3', '#004ecc', '#0058e6', '#0062ff', '#1a71ff',
              '#3381ff', '#4d91ff', '#66a1ff']
          },
          animations: true,
          labels: false,
          gradient: false,
          legend: false,
          legendTitle: 'defautLegend',
          xAxisLabel: 'defaultX',
          yAxisLabel: 'defaultY',
          roundEdges: true
        };
      }
    );
  }

  private setKeepPerPriceType(): void {
    this.metricService.getKeptPerPriceType(this.premiumPlan.id).subscribe(
      response => {
        this.keepsPerPriceType = response;
        this.keepsPerPriceTypeData = this.chartService.parseReleasePriceType(this.keepsPerPriceType);
        this.verticalBarChartBaseConfig = {
          view: [300, 0],
          results: this.keepsPerPriceTypeData,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#002766', '#003180', '#003b99', '#0044b3', '#004ecc', '#0058e6', '#0062ff', '#1a71ff',
              '#3381ff', '#4d91ff', '#66a1ff']
          },
          animations: true,
          labels: false,
          gradient: false,
          legend: false,
          legendTitle: 'defautLegend',
          xAxisLabel: 'defaultX',
          yAxisLabel: 'defaultY',
          roundEdges: true
        };
      }
    );
  }
  private setTotalRevenueBySale(): void {
    this.metricService.getTotalRevenueBySaleType().subscribe(
      totalRevenueBySale => {
        if (totalRevenueBySale['extras'] === null) { // fix console errors extras = null
          delete totalRevenueBySale['extras'];
        }
        this.totalRevenueBySaleData = totalRevenueBySale;
        this.totalRevenueBySaleConfig = {
          view: [],
          results: this.chartService.parseData(this.totalRevenueBySaleData),
          animations: true,
          labels: true,
          legend: false,
          legendPosition: 'below',
          legendTitle: 'Total Revenue Gain',
          doughnut: false,
          gradient: false,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#002766', '#003180', '#003b99', '#0044b3', '#004ecc', '#0058e6', '#0062ff', '#1a71ff',
              '#3381ff', '#4d91ff', '#66a1ff']
          }
        };
      }
    );
  }

}
