import { Inject, Injectable } from '@angular/core';
import { GeneralHandler } from './general.handler';
import { DvmService } from '../../shared/services/dvm.service';
import { FlowControlService } from '../services';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { createPopper, Placement } from '@popperjs/core';
import { PriceModel } from 'src/app/shared';

// tslint:disable-next-line: class-name
export interface customerPopover {
    account_id?: string;
    first_name?: string;
    last_name?: string;
    account_name?: string;
    plan?: string;
    status?: string;
    priceType?: string;
    price?: Array<PriceModel>;
}

// tslint:disable-next-line: class-name
export interface seatObjectPopover {
    id?: string;
    section?: string;
    row?: string;
    seat?: string;
    priceType?: string;
    price?: Array<PriceModel>;
    customers?: Array<customerPopover>;
    comments?: string;
}
@Injectable()
export class PopoverCreateHandler extends GeneralHandler {
    tooltip: HTMLElement;

    constructor(protected dvmService: DvmService,
                private flowControl: FlowControlService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        super('enter', dvmService);
    }

    protected handle(obj) {
        if (obj.nodes.length && (obj.nodes[0].state === 'available' || obj.nodes[0].state === 'selected')) {
            const node = obj.nodes[0];
            // console.log('Popover Handler:', node);

            // Receive tooltip HTML Element
            this.tooltip = document.querySelector('#tooltip');

            // Generate tooltip content based on node type
            let tooltipContent;
            if (node.type === 'section' && obj.instance.getMapId() === this.dvmConfig.map_id) {
                const areaObject = this.flowControl.availabilityBlockmap[node.id];
                tooltipContent = this.sectionPopoverContent(areaObject, node);
            } else if (node.type === 'seat') {
                tooltipContent = this.seatPopoverContent(node);
            }

            // Create content HTML Element, clean previous popover and append
            const tooltipContentHTML = document.createElement('div');
            tooltipContentHTML.innerHTML = tooltipContent.trim();
            const popoverBodyNode = document.querySelector('#tooltip .popover-body');
            while (popoverBodyNode.firstChild) {
                popoverBodyNode.removeChild(popoverBodyNode.firstChild);
            }
            popoverBodyNode.appendChild(tooltipContentHTML);


            // Calculate popover placement
            let placement: Placement;
            if (node.anchor.dom[1] > (document.getElementById('map-interface-card').offsetHeight / 2)) {
                placement = 'top';
            } else {
                placement = 'bottom';
            }

            // Initiate popover
            createPopper(node, this.tooltip, {
                placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: [],
                        }
                    }
                ],
            });

            // Display popover
            this.tooltip.setAttribute('data-show', '');
        }
    }

    public sectionPopoverContent(areaMap, node) {
        // const areaName = node.id.split('_')[1];
        // let tooltipContent = `
        //                     <div class='ticket-selected-view'>
        //                         <div class='ticket-selected-view-img'></div>
        //                      </div>
        //                     <ul class='list-unstyled ticket-selected'>
        //                         <li style='text-align: center;'>
        //                             <span class='ticket-lab'>Section</span>
        //                             <span class='ticket-val'>` + areaName + `</span>
        //                         </li>
        //                     </ul>`;
        // const price = this.flowControl.areapricing[node.id];
        // if (price) {
        //     if (price['min'] !== price['max']) {
        //         tooltipContent += '<ul class=\'list-unstyled ticket-selected\'>' +
        //             '<li style=\'display:block;\'>' +
        //             '<span class=\'ticket-lab\'>Price </span>' +
        //             '<span class=\'ticket-val\'>$' +
        //             Number.parseFloat(price['min']).toLocaleString(undefined, { maximumFractionDigits: 2 })
        //             + ' - $' +
        //             Number.parseFloat(price['max']).toLocaleString(undefined, { maximumFractionDigits: 2 })
        //             + '</span>' +
        //             '</li>' +
        //             '</ul>';
        //     } else {
        //         tooltipContent += '<ul class=\'list-unstyled ticket-selected\'>' +
        //             '<li style=\'display:block;\'>' +
        //             '<span class=\'ticket-lab\'>Price </span>' +
        //             '<span class=\'ticket-val\'>$' +
        //             Number.parseFloat(price['min']).toLocaleString(undefined, { maximumFractionDigits: 2 })
        //             + '</span>' +
        //             '</li>' +
        //             '</ul>';
        //     }
        // }
        let tooltipContent = `
            <ul class='list-unstyled ticket-selected'>
                <li style='text-align: center;'>
                    <span class='ticket-lab'>Section</span>
                    <span class='ticket-val'>` + node.id.split('_')[1] + `</span>
                </li>
            </ul>`;
        if (areaMap !== undefined) {
            tooltipContent +=
            '<ul style=\'margin-top: 5px\' class=\'list-unstyled ticket-selected\'>' +
            '<li>' +
            '<span class=\'ticket-lab\'>Price: </span> ' +
            // tslint:disable-next-line: max-line-length
            '<span class=\'ticket-val\'>$' + this.formatPrice(areaMap.min_price) + ' -  $' + this.formatPrice(areaMap.max_price) + '</span>' +
            '</li>' +
            '</ul>';
        }
        return tooltipContent;

    }

    public seatPopoverContent(node) {
        let seatObject;
        if (this.flowControl.seatAll) {
          seatObject = this.flowControl.availabilitySeatmap[this.flowControl.seatAll.id];
        } else {
          seatObject = this.flowControl.availabilitySeatmap[node.id];
        }
        if (!seatObject) {
            return;
        }
        console.log('SILLA POPOVER ==> ', seatObject);
        // Seatmap
        let seating: seatObjectPopover;
        if (this.flowControl.viewerMode === 'inventory') {
            seating = {
                id: seatObject.id,
                section: seatObject.id.split('-')[0].split('_')[1],
                row: seatObject.id.split('-')[1],
                seat: seatObject.id.split('-')[2],
                customers: []
            };
            if (seatObject.comments.length > 0) {
              seating.comments = seatObject.comments[0].comment;
            }
        } else if (this.flowControl.viewerMode === 'customer') {
            seating = {
                id: node.id,
                section: node.id.split('-')[0].split('_')[1],
                row: node.id.split('-')[1],
                seat: node.id.split('-')[2],
                customers: []
            };

            Object.defineProperty(seating, 'priceType', {
                get: () => {
                    if (seatObject) {
                        if (seatObject.price_type === null) {
                            return undefined;
                        } else {
                            return seatObject.price_type.name;
                        }
                    }
                }, enumerable: true
            });
            Object.defineProperty(seating, 'price', {
                get: () => {
                    if (seatObject) {
                        if (seatObject.price_type === null) {
                            return undefined;
                        } else {
                            return seatObject.price_type.prices;
                        }
                    }
                }, enumerable: true
            });
        }

        if (seatObject.hasOwnProperty('final_event_status') && seatObject.final_event_status.length !== 0) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < seatObject.final_event_status.length; i++) {
                const customer: customerPopover = {
                    status: seatObject.final_event_status[i].status,
                };
                if (seatObject.price_type !== null) {
                    customer.priceType = seatObject.price_type.name;
                    customer.price = seatObject.price_type.prices;
                }
                customer.status = seatObject.final_event_status[i].status;
                customer.plan = seatObject.final_event_status[i].plan.name;
                if (seatObject.final_event_status[i].customer !== null) {
                    if (Object.keys(seatObject.final_event_status[i].customer).length !== 0) {
                        customer.account_id = seatObject.final_event_status[i].customer.id;
                        customer.first_name = seatObject.final_event_status[i].customer.first_name;
                        customer.last_name = seatObject.final_event_status[i].customer.last_name;
                        customer.account_name = seatObject.final_event_status[i].customer.account_name;
                    }
                }
                seating.customers.push(customer);
            }
            // seating.status = seatObject.final_event_status[0].status;
        } else {
            let customer: customerPopover = {};
            if (seatObject.locked === true) {
                customer = {
                    status: 'Locked'
                };
            } else {
                customer = {
                    status: 'Available'
                };
            }

            if (seatObject.price_type !== null) {
                customer.priceType = seatObject.price_type.name;
                customer.price = seatObject.price_type.prices;
            }
            seating.customers.push(customer);
        }
        // Hover style for the seat
        const styleHover: any = {
            none: {
                fillStyle: 'white',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 0.2,
                cursor: 'pointer'
            }
        };
        // (this.dvmService.viewer as any).setNodeCustomStyles(node.id, [styleHover]);
        // End hover style
        const nodeSeat = node.id.split('-');
        const section = nodeSeat[0].split('_')[1];
        const row = nodeSeat[1];
        const seat = nodeSeat[2];
        let tooltipContent =
        '<ul style=\'width: 280px\' class=\'flex ticket-selected-seat\'>' +
        '<li style=\'display:block;\'>' +
        '<span class=\'ticket-lab\'>Section</span> ' +
        '<span class=\'ticket-val\'>' + seating.section + '</span>' +
        '</li>' +
        '<li>' +
        '<span class=\'ticket-lab\'>Row</span> ' +
        '<span class=\'ticket-val\'>' + seating.row + '</span>' +
        '</li>' +
        '<li>' +
        '<span class=\'ticket-lab\'>Seat</span> ' +
        '<span class=\'ticket-val\'>' + seating.seat + '</span>' +
        '</li>' +
        '</ul>';
        const price = this.flowControl.seatpricing[node.id];
        let marketPrice;
        let customerPrice;
        if (price) {
            if (price.length > 1) {
                customerPrice = Math.min(price[1], price[0]);
                marketPrice = Math.max(price[1], price[0]);
            } else {
                customerPrice = price[0];
                marketPrice = price[0];
            }
        }
        // if (marketPrice) {
        //     tooltipContent += '<ul class=\'list-unstyled ticket-selected\'>' +
        //         '<li style=\'display:block;\'>' +
        //         '<span class=\'ticket-lab\'>Market price </span> ' +
        //         '<span class=\'ticket-val\'>$' +
        //         Number.parseFloat(marketPrice).toLocaleString(undefined, { maximumFractionDigits: 2 })
        //         + '</span>' +
        //         '</li>' +
        //         '</ul>';
        // }
        // if (customerPrice) {
        //     tooltipContent += '<ul class=\'list-unstyled ticket-selected\'>' +
        //         '<li style=\'display:block;\'>' +
        //         '<span class=\'ticket-lab\'>Your price </span> ' +
        //         '<span class=\'ticket-val\'>$' +
        //         Number.parseFloat(customerPrice).toLocaleString(undefined, { maximumFractionDigits: 2 })
        //         + '</span>' +
        //         '</li>' +
        //         '</ul>';
        // }
        // '<ul class=\'list-unstyled ticket-selected\'>';
        if (typeof seating.price !== 'undefined') {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < seating.price.length; i++) {
                tooltipContent +=
                    '<ul class=\'list-unstyled ticket-selected\'>' +
                    '<li style=\'display:block;\'>' +
                    '<span class=\'ticket-lab\'>Price:</span> ' +
                    '<span class=\'ticket-val\'>$' + seating.price[i].price + '</span>' +
                    '</li>' +
                    '</ul>';
            }
        }
        if (typeof seating.priceType !== 'undefined') {
            tooltipContent +=
                '<ul class=\'list-unstyled ticket-selected\'>' +
                '<li style=\'display:block;\'>' +
                '<span class=\'ticket-lab\'>Price type:</span> ' +
                '<span class=\'ticket-val\'>' + seating.priceType + '</span>' +
                '</li>' +
                '</ul>';
        }

        if (seating.customers.length !== 0) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < seating.customers.length; i++) {
                tooltipContent +=
                    '<ul class=\'list-unstyled ticket-selected\'>';
                if (seating.customers[i].hasOwnProperty('price') && seating.customers[i].price.length !== 0) {
                    // for (let j = 0; j < seating.customers[i].price.length; j++) {
                    //   content +=
                    //     '<li style=\'display:block;\'>' +
                    //     '<span class=\'ticket-lab\'>Price:</span> ' +
                    //     '<span class=\'ticket-val\'>$' + seating.customers[i].price[j].price + '</span>' +
                    //     '</li>';
                    // }
                    // content +=
                    //   '<li style=\'display:block;\'>' +
                    //   '<span class=\'ticket-lab\'>Price type:</span> ' +
                    //   '<span class=\'ticket-val\'>' + seating.customers[i].priceType + '</span>' +
                    //   '</li>';
                } else {
                    tooltipContent +=
                        '<ul class=\'list-unstyled ticket-selected\'>' +
                        '<li style=\'display:block;\'>' +
                        '<span class=\'ticket-lab\'>Price:</span> ' +
                        '<span class=\'ticket-val\'>Dont have Price Type</span>' +
                        '</li>' +
                        '</ul>';
                }
                tooltipContent +=  '<span class=\'ticket-val\'>-----------------</span>' +
                    '<ul class=\'list-unstyled ticket-selected\' style=\'margin-top:5px;\' >' +
                    '<li>' +
                    '<span class=\'ticket-lab\'>Status</span> ' +
                    '<span class=\'ticket-val\'>' + seating.customers[i].status + '</span>' +
                    '</li>' +
                    '</ul>';

                if (seating.customers[i].hasOwnProperty('first_name')) {
                    tooltipContent +=
                        '<ul class=\'list-unstyled ticket-selected\'>' +
                        // '<li style=\'display:block;\'>' +
                        // '<span class=\'ticket-lab\'>Account ID</span> ' +
                        // '<span class=\'ticket-val\'>' + seating.customers[i].account_id + '</span>' +
                        // '</li>' +
                        '<li style=\'display:block;\'>' +
                        '<span class=\'ticket-lab\'>Customer</span> ' +
                        '<span class=\'ticket-val\'>' + seating.customers[i].account_name + '</span>' +
                        '</li>' +
                        '</ul>';
                }
                // https://mmcbcn.atlassian.net/browse/IT-2259 put plan on map to know where comes the internal hold or
                // owned and so
                if (seating.customers[i].plan) {
                    tooltipContent += '<ul class=\'list-unstyled ticket-selected\'>' +
                      '<li>' +
                      '<span class=\'ticket-lab\'>Plan</span> ' +
                      '<span class=\'ticket-val\'>' + seating.customers[i].plan + '</span>' +
                      '</li>' +
                      '</ul>';
                }
            }
        }
        if (seating.hasOwnProperty('comments')) {
          tooltipContent +=
            '<ul class=\'list-unstyled ticket-selected\' style=\'margin-top: 5px;\'>' +
            '<li style=\'display:block;\'>' +
            '<span class=\'ticket-lab\'>COMMENT<br></span> ' +
            '<span class=\'ticket-val\'>' + seating.comments + '</span>' +
            '</li>' +
            '</ul>';
        }
        tooltipContent += '</ul>';
        return tooltipContent;
    }

    formatPrice(price: number): string {
        // tslint:disable-next-line:variable-name
        const _price = Math.abs(price);
        return _price.toLocaleString('en-US', {maximumFractionDigits: 2});
      }
}
