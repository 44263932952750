import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InventoryService } from './inventory.service';
import { PlanModel } from '../../shared';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InventoryResolveService {

  constructor(private inventoryService: InventoryService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<{ plans: PlanModel[]; fullPlanId: number }> {
    return this.inventoryService.getPlans().toPromise().then((planList) => {
      const result = { plans: planList, fullPlanId: 1 };
      result.fullPlanId = planList.filter((plan) => plan.tier === 1)[0].id;
      return result;
    });
  }
}
