import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from './session-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient, private sessionService: SessionStorageService) { }

  public seasons: any = {}; // El ultimo valor de la lista es la season "actual"
  public seasonList: Array<string> = [];
  // subject que pasa el valor de la season actual ( la mas nueva )
  private currentSeason: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  // subject que pasa el valor de la season seleccionada por el usuario
  private selectedSeason: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private setSeason = tap((seasons: any) => {
    const listOfSeasonKeys = Object.keys(seasons);
    // se setea la season actual, la actual siempre sera la ultima
    const actualSeason: string = listOfSeasonKeys[listOfSeasonKeys.length - 1];
    this.setCurrentSeason(actualSeason);
    if (this.sessionService.getItem('season') != null) { // si en la session aun tenemos una season, la mantenemos.
      this.selectedSeason.next(this.sessionService.getItem('season'));
    } else {
      this.selectedSeason.next(actualSeason);
    }
    this.seasons = seasons;
    this.seasonList = listOfSeasonKeys;
  });

  public setSelectedSeason(sison) {
    this.selectedSeason.next(sison);
  }

  public getSelectedSeason(): string {
    return this.selectedSeason.getValue();
  }

  public getObservableSelectedSeason(): Observable<string> {
    return this.selectedSeason.asObservable();
  }

  public setCurrentSeason(sison) {
    this.currentSeason.next(sison);
  }

  public getCurrentSeason(): string {
    return this.currentSeason.getValue();
  }

  public getObservableCurrentSeason(): Observable<string> {
    return this.currentSeason.asObservable();
  }

  // Check if filter object have any value
  filterisEmpty(filter: any): boolean {
    let status = true;
    for (const key of Object.keys(filter)) {
      if (filter[key]) {
        status = false;
        break;
      }
    }
    return status;
  }

  // receive season list
  getSeasons(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`/season-list/`).pipe(this.setSeason);
    // TODO change the return, this is only for development purposes
    // return new Observable(function subscribe(subscriber) {
    //   subscriber.next(['2019', '2019 - Open House', '2020', '2020 - Open House', '2021', '2021 - Open House']);
    // }).pipe(this.setSeason);
  }


  public refreshPage(router: Router): void {
    // save current route first
    const currentRoute = router.url;

    router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      router.navigate([currentRoute]); // navigate to same route
    });
  }
}
