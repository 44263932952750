import { Inject, Injectable } from '@angular/core';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { FlowControlService } from '../services/flow-control.service';
import { PricingService } from '../services';

@Injectable()
export class ClickHandler extends GeneralHandler {

    constructor(
        @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
        protected dvmService: DvmService,
        private flowService: FlowControlService,
        private pricingService: PricingService) {
        super('click', dvmService);
    }

    protected handle(obj): void {
        // Close popovers
        const tooltip: HTMLElement = document.querySelector('#tooltip');
        tooltip.removeAttribute('data-show');

        // Handle
        const viewer = obj.instance;
        const nodes = obj.nodes;
        if (nodes.length) {
            console.log('Click Handler:', nodes);
            if (nodes[0].type === 'section') {
                this.sectionClickHandler(nodes[0]);
            } else {
                this.seatClickHandler(nodes[0]);
            }
        }
    }

    sectionClickHandler(node): void {
        if (node.state === 'available') {
            const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
            let nextMap = node.id;
            if (nextMap.includes('(INT)')) {
              nextMap = nextMap.split('(INT)')[0];
            }
            loadOptions.map_id = nextMap;
            loadOptions.venue_id = this.dvmService.viewer.getVenueId();
            this.dvmService.loadMap(loadOptions);
        }
    }

    seatClickHandler(node): void {
        // sets the data by seat id
        const isSuite = (node.id.includes('A') || node.id.includes('B') || node.id.includes('C'));
        if (node.state === 'selected') {
            if (node.id.includes('TB')) {
              this.dvmService.viewer.unselect(Object.keys(this.flowService.availabilitySeatmap));
              delete this.flowService.seatHash[this.flowService.seatAll.id];
            } else {
              this.dvmService.viewer.unselect(node.id);
              delete this.flowService.seatHash[node.id];
            }
            // If there is 1 element on the hash, then get's the last element from the hash and sets the info on the side-interface
            if (Object.keys(this.flowService.seatHash).length === 1) {
                this.flowService.setSeatData(this.flowService.availabilitySeatmap[Object.keys(this.flowService.seatHash)[0]]);
            }
            // If there is no elements on the hash, then sets the info null to clean the side-interface
            if (Object.keys(this.flowService.seatHash).length === 0) {
                this.flowService.setSeatData(null);
            }
        } else {
            if (node.id.includes('TB')) {
              this.flowService.setSeatData(this.flowService.seatAll);
              this.dvmService.viewer.select(Object.keys(this.flowService.availabilitySeatmap));
              this.flowService.seatHash[this.flowService.seatAll.id] = true;
            } else {
              this.flowService.setSeatData(this.flowService.availabilitySeatmap[node.id]);
              this.dvmService.viewer.select(node.id);
              this.flowService.seatHash[node.id] = true;
            }
        }

        this.pricingService.seatsSelectedLengh.next(this.dvmService.viewer.getNodesByState('seat', 'selected').length);
    }
}
