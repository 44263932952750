import { Component, OnInit } from '@angular/core';
import { DjangoSessionAuthenticationService } from '../auth';
import { Observable } from 'rxjs';
import { AuthorizationData, BackofficeUser } from '../shared/models';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../services/notification.service';
import { CommonService } from '../services';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  screen: {
    login: boolean,
    forgot: boolean,
    verifyCode: boolean,
  };
  loginData: AuthorizationData = { username: '', password: '' };
  otp: string;
  user$: Observable<BackofficeUser | false>;
  errors: boolean;
  verificationErrors: boolean;
  email: string;

  constructor(private auth: DjangoSessionAuthenticationService,
              private notificationService: NotificationService,
              private commonService: CommonService,
              private localStorage: LocalStorageService,
              private router: Router) {
    this.errors = false;
    this.screen = {
      login: true,
      forgot: false,
      verifyCode: false,
    };
  }

  login() {
    this.auth.login(this.loginData).subscribe(
      (response) => {
        this.showCodeVerifivation();
      },
      (err) => {
        this.showError(err);
      }
    );

  }

  verifyCode() {
    this.auth.verifyCode(this.loginData, this.otp).subscribe(
      (response) => {
        if (response.type.includes('sales_person')) {
          this.router.navigate(['/inventory/map']);
        } else {
          this.router.navigate(['/home']);
        }
      },
      (err) => {
        this.showVerificationError(err);
      }
    );
  }

  showError(error): void {
    this.errors = true;
  }

  showVerificationError(error): void {
    this.verificationErrors = true;
  }

  ngOnInit() {
    // REAL CASE
    // this.user$ = this.auth.getUserLogged$();
    // this.user$.subscribe(user => {
    //   if (user) {
    //   }
    // });
  }

  showForgotPassword(): void {
    this.screen.login = false;
    this.screen.forgot = true;
    this.screen.verifyCode = false;
    this.errors=false;
    this.verificationErrors=false;
  }

  backToLogin(): void {
    this.screen.login = true;
    this.screen.forgot = false;
    this.screen.verifyCode = false;
    this.email = '';
    this.otp = '';
    this.errors=false;
    this.verificationErrors=false;
  }

  showCodeVerifivation(): void {
    this.screen.login = false;
    this.screen.forgot = false;
    this.screen.verifyCode = true;
    this.otp = '';
    this.errors=false;
    this.verificationErrors=false;
  }

  forgotpassword(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.auth.passwordReset(form.value.email).subscribe((response) => {
      this.backToLogin();
      this.notificationService.showSuccess('The email was sent');
    }, (err) => {
      console.error(err);
      this.notificationService.showError('Email could not be sent');
    });
  }
}
