import { Injectable } from '@angular/core';
import { SeatDataModel, SectionDataModel } from 'src/app/inventory-management/shared';
import { Observable, Subject } from 'rxjs';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { PricingService } from '.';

@Injectable({
  providedIn: 'root'
})
export class FlowControlService {

  public availabilityBlockmap: { [key: string]: SectionDataModel } = {};
  public availabilitySeatmap: { [key: string]: SeatDataModel } = {};
  public sectionSelected: string;
  public planSelected: number;
  public flowStep: 'blockmap' | 'seatmap';
  private sectionData$ = new Subject<SectionDataModel>();
  private seatData$ = new Subject<SeatDataModel>();
  public isGa: boolean;
  public seatHash;
  public viewerMode: 'customer' | 'inventory';
  public colorsmode: 'inventory' | 'pricing';
  public seatAll: SeatDataModel = null; // seat that holds if exists the section-all-seats seat



  constructor(private dvmService: DvmService,
              private pricingService: PricingService,) {
  }

  // tslint:disable-next-line: variable-name
  setSectionData(_sectionData: SectionDataModel) {
    this.sectionData$.next(_sectionData);
  }

  getSectionData(): Observable<SectionDataModel> {
    return this.sectionData$.asObservable();
  }

  // tslint:disable-next-line:variable-name
  setSeatData(_seatData: SeatDataModel): void {
    this.seatData$.next(_seatData);
  }

  getSeatData(): Observable<any> {
    return this.seatData$.asObservable();
  }

  // iterate through availability and get a seat with row all if exists
  public setSeatAll(availability: SeatDataModel[]) {
    this.seatAll = null;
    for (const seat of availability) {
      if (seat.seat_row === 'all' && seat.price_type !== null) {
        this.seatAll = seat;
      }
    }
  }

  renewHash(): void {
    const currentSelection = this.dvmService.viewer.getNodesByState('seat', 'selected');
    for (const seat of currentSelection) {
      if (!this.seatHash[seat.id]) {
        this.seatHash[seat.id] = true;
      }
    }
    this.pricingService.seatsSelectedLengh.next(this.dvmService.viewer.getNodesByState('seat', 'selected').length);
  }


}
